import axios from "axios";

export const headers = {
	"Tenant-Access-Token": "NzUyYjEzOTU3M2I0MDljMDc3ZWQzNzAwODRjMzc3MDU=",
	"Tenant-Access-Key": "0iUMliU4qdBXnQ",
};

export const api = axios.create({
	baseURL: "https://tiltlabs-dev.confirmu.com",
	headers,
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
